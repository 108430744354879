import Card from "./Card";
import projects from "../models/projects";

const Work = () => {
  return (
    <section id="projects" className="w-full lg:w-4/6 xl:max-w-5xl mt-20">
      <div className="mb-10">
        <h1 className="text-xl font-bold text-neutral-300">Selected Work</h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        {projects.slice(0, 4).map((project, index) => (
          <Card key={index} project={project} />
        ))}
      </div>

      <a
        href="https://aryanarora.vercel.app/"
        target="_blank"
        rel="noreferrer"
        className="flex justify-center"
      >
        <a
          href="/projects"
          className="w-max px-5 text-center font-bold py-2 rounded-lg mt-10 bg-[#0047FF] text-white animate-short"
        >
          View All Projects
        </a>
      </a>
    </section>
  );
};

export default Work;
