const Footer = () => {
  return (
    <>
      <nav className="border-t my-20 w-full border-gray-200 bg-white">
        <div className="px-10 md:mx-auto md:max-w-5xl md:px-8">
          <div className="pt-20 md:flex md:items-start md:justify-between md:pb-20">
            <div className="md:w-2/3">
              <h1 className="font-sans font-semibold text-xl">
                Aryan Arora{" "}
                <span className="text-slate-600 ml-3 text-base font-normal">
                  Software Engineer
                </span>
              </h1>
            </div>
            <div className="flex space-x-28 py-14 md:w-1/3 md:justify-end md:space-x-40 md:px-2 md:py-0">
              <div>
                <h6 className="pb-2 text-xl font-semibold">Pages</h6>
                <div className="flex flex-col space-y-3 pt-2 text-zinc-400 underline-offset-8">
                  <a href="/" className="hover:text-slate-700 animate-short">
                    Home
                  </a>
                  <a
                    href="/projects"
                    className="hover:text-slate-700 animate-short"
                  >
                    Projects
                  </a>
                  <a
                    href="/vision"
                    className="hover:text-slate-700 animate-short"
                  >
                    Vision
                  </a>
                  <a
                    href="/contact"
                    className="hover:text-slate-700 animate-short"
                  >
                    Contact
                  </a>
                </div>
              </div>
              <div>
                <h6 className="pb-2 text-xl font-semibold">Connect</h6>
                <div className="flex flex-col space-y-3 pt-2 text-zinc-400">
                  <a
                    className="hover:text-slate-700 animate-short"
                    href="mailto:aryanxarora@gmail.com"
                    target="_blank"
                    rel="noreferer"
                  >
                    Email
                  </a>
                  <a
                    className="hover:text-slate-700 animate-short"
                    href="https://www.linkedin.com/in/aryanxarora/"
                    target="_blank"
                    rel="noreferer"
                  >
                    LinkedIn
                  </a>
                  <a
                    className="hover:text-slate-700 animate-short"
                    href="https://github.com/aryanxarora"
                    target="_blank"
                    rel="noreferer"
                  >
                    GitHub
                  </a>
                  <a
                    className="hover:text-slate-700 animate-short"
                    href="https://www.instagram.com/aryanxarora"
                    target="_blank"
                    rel="noreferer"
                  >
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-dashed py-14 md:flex md:justify-between text-sm text-neutral-400">
            <span>© Copyright Aryan Arora 2024</span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Footer;
