import proview from '../assets/proview.png'
import stripe from '../assets/stripe.png'

const projects = [
    {
        title: 'Cashout',
        desc: 'Income tracking and fund allocation – Streamline your finances. Easily log income, and let Cashout smartly allocate it to categories',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1708922069/portfolio/projects/j41xiynozgq8fw7bxhld.png"],
        github: 'https://github.com/aryanxarora/Cashout',
        demo: 'https://cashcompass.vercel.app/',
    },
    {
        title: "Diro's World",
        desc: 'Never-ending fantasy role-playing game (RPG) powered by Artificial Intelligence. A new storyline every time you play!',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1708908101/portfolio/projects/lpmtg6umvftbqpbni2ry.gif"],
        github: 'https://github.com/aryanxarora/Diro-World',
        demo: 'https://diro-world.vercel.app/',
        genai: true
    },
    {
        title: 'Proview',
        desc: 'Stunning landing page for a Real Estate marketing company. Designed and Developed by moi :)',
        image: [proview],
        github: 'https://github.com/aryanxarora/Proview-Real-Estate',
        demo: 'https://proview.vercel.app/'
    },
    {
        title: 'Stripe Navbar',
        desc: 'A fully responsive and dynamic plug-n-play navbar component inspired by Stripe',
        image: [stripe],
        github: 'https://github.com/aryanxarora/Stripe-Navbar',
        demo: 'https://navbar-stripe.vercel.app/'
    },
    {
        title: 'Ergofix Philippines',
        desc: 'Standing desk e-commerce business website. Shop ergonomic standing desks and accessories.',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341507/portfolio/projects/mkllmdzrdiycoju8fzgr.png"],
        demo: 'https://ergofix.netlify.app/'
    },
    {
        title: 'Mobility Invoicing',
        desc: 'Billing and invoicing app created for companies like Bell and Rogers to manage customer plans and payments',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709355181/portfolio/projects/kuq2xgq2j3b5bmru8yip.png"],
        github: 'https://github.com/aryanxarora/Mobility-Invoicing',
        demo: 'https://billing-portal.vercel.app/'
    },
    {
        title: 'Kitchenette',
        desc: 'A recipe book and sharing app for foodies. Share your favorite recipes and discover new ones!',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341507/portfolio/projects/aeu4ep9z8ijym7xu6g16.png"],
        github: 'https://github.com/aryanxarora/Kitchenette',
    },
    {
        title: 'Key Management Protocol', 
        desc: 'Undergraduate thesis: A lightweight, secure, and efficient key management protocol for IoT devices ',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341508/portfolio/projects/wnol4xzjwwtnz7iittem.png"],
    },
    {
        title: 'Alpha Phi Beta', 
        desc: 'Freelance project for a law fraternity of University of the Philippines. A website that shows hertiage and history',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341508/portfolio/projects/lfirw3biqqyqc4bmqkfv.png"],
        demo: 'https://alpha-phi-beta.netlify.app/index.html'
    },
    {
        title: 'Employee Track', 
        desc: 'HR management system for school teachers and staff. Track employee attendance, leaves, and payroll',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341509/portfolio/projects/jhfxnimr8j5mqcvyqlqa.png"],
        github: 'https://github.com/aryanxarora/HR-Management-System'
    },
    {
        title: 'Warehouse Management', 
        desc: 'Database design for a warehouse management system. Track inventory, orders, and shipments',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341506/portfolio/projects/tjwyvkzi0cq58xny7mte.png"],
    },
    {
        title: 'First Ever Website', 
        desc: 'My first ever website. A simple portfolio with HTML, CSS, and JavaScript. A nostalgic throwback!',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341506/portfolio/projects/otzxogkpglxf6vradvzj.png"],
        github: 'https://github.com/aryanxarora/aryanxarora.github.io',
        demo: 'https://aryanxarora.github.io/'
    },
    {
        title: 'AI Tic Tac Toe', 
        desc: 'A simple tic-tac-toe game with three levels of AI difficulty. Built with Python and Machine Learning',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1709341505/portfolio/projects/oqm1qpn56sypz6gcdpcj.png"],
        github: 'https://github.com/aryanxarora/AI-Mini-Games',
    },
]

export const vision = [
    {
        title: 'After Hours',
        desc: 'A social speakeasy with excellent craft cocktails inspired by Japanese taste profiles',
        image: ["https://res.cloudinary.com/dm0kkwirn/image/upload/v1708674511/portfolio/vision/apres/dkga6g2xh12nukzcf8gj.png", "https://res.cloudinary.com/dm0kkwirn/image/upload/v1708675884/portfolio/vision/apres/dfbcnv8q4pn8bfvvyemt.png"],
        // github: 'https://github.com',
        // demo: 'https://stripe.com'
    }
]

export default projects;
