import { motion } from "framer-motion";
import react from "../assets/react.svg";
import next from "../assets/next.svg";
import tailwind from "../assets/tailwind.svg";
import redux from "../assets/redux.svg";

const Hero = () => {
  return (
    <div className="overflow-hidden">
      <motion.div
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        className="flex flex-col items-center relative"
      >
        {/* announcement */}
        <div className="flex pt-20 lg:pt-30 2xl:pt-40 pb-10">
          <div className="border-1 rounded-full w-auto flex gap-3 border border-1 border-slate-300 px-3 py-1 justify-center items-center">
            <svg
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
              className="w-1 animate-ping fill-[#0047FF]"
            >
              <circle cx="50" cy="50" r="50" />
            </svg>

            <h1 className="text-xs lg:text-sm font-mono">
              Actively looking for roles
            </h1>
          </div>
        </div>

        {/* heading */}
        <h1 className="font-serif text-[56px] lg:text-[80px] text-center leading-none w-2/3">
          Fueling the <span className="text-[#0047FF] font-serif">dev</span> in
          Software Developer
        </h1>
        {/* subheading */}
        <p className="my-10 font-mono text-sm lg:text-base w-2/3 lg:w-1/3 text-center">
          Implementing AI search and generative experiences for enterprises @ Coveo
        </p>
        {/* cta button */}
        <>
          <a
            href="/contact"
            className="text-white font-mono font-semibold bg-[#0047FF] px-5 py-2 rounded-lg border-2 border-blue-700 z-10"
          >
            Contact
          </a>
          <div className="font-mono text-slate-400 text-sm mt-5 z-10">
            <a
              href="https://www.linkedin.com/in/aryanxarora/"
              rel="noreferrer"
              target="_blank"
              className="hover:bg-slate-100 p-2 rounded-md"
            >
              LinkedIn
            </a>{" "}
            <span className="text-xs">|</span>{" "}
            <a
              href="https://github.com/aryanxarora"
              target="_blank"
              rel="noreferrer"
              className="hover:bg-slate-100 p-2 rounded-md"
            >
              Github
            </a>
          </div>
        </>

        {/* props */}
        <div className="absolute hidden xl:block lg:mt-0 2xl:mt-16">
          <motion.div
            layout
            initial={{ x: -300, y: 430, opacity: 0 }}
            animate={{ x: -300, y: 400, opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
            className="group"
          >
            <img
              src={react}
              alt="react.js"
              className="w-4/5 group-hover:animate-spin"
            />
          </motion.div>
          <motion.div
            layout
            initial={{ x: -500, y: -20, opacity: 0 }}
            animate={{ x: -500, y: -50, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <img src={next} alt="react.js" className="w-4/5" />
          </motion.div>
          <motion.div
            layout
            initial={{ x: 550, y: -270, opacity: 0 }}
            animate={{ x: 550, y: -300, opacity: 1 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            <img src={tailwind} alt="react.js" className="w-4/5" />
          </motion.div>
          <motion.div
            layout
            initial={{ x: 400, y: -170, opacity: 0 }}
            animate={{ x: 400, y: -200, opacity: 1 }}
            transition={{ duration: 1, delay: 0.7 }}
          >
            <img src={redux} alt="react.js" className="w-4/5" />
          </motion.div>
        </div>
        <div className="absolute sm:hidden">
          <motion.div
            layout
            initial={{ x: -70, y: 530, opacity: 0 }}
            animate={{ x: -70, y: 500, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="group"
          >
            <img
              src={react}
              alt="react.js"
              className="w-3/5 group-hover:animate-spin"
            />
          </motion.div>
          <motion.div
            layout
            initial={{ x: -100, y: -50, opacity: 0 }}
            animate={{ x: -100, y: -80, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <img src={next} alt="react.js" className="w-3/5" />
          </motion.div>
          <motion.div
            layout
            initial={{ x: 230, y: 100, opacity: 0 }}
            animate={{ x: 230, y: 70, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <img src={tailwind} alt="react.js" className="w-2/5" />
          </motion.div>
        </div>
      </motion.div>
      <div className="mt-20 xl:mt-52 flex flex-col items-center">
        {/* <h1>Hello world</h1> */}
      </div>
    </div>
  );
};

export default Hero;
