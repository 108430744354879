import yerko from '../assets/yerko.jpeg';
import silviya from '../assets/silviya.jpeg';
import jerry from '../assets/jerry.jpeg';
import jolson from '../assets/jolson.jpeg';

const reviews = [
    {
        user: 'Yerko Arincibia',
        title: 'Marketing Director, Martini Vispak',
        image: yerko,
        review: 'Aryan is proactive, detail-oriented, and has a great work ethic. He is a quick learner and has a strong understanding of web development. He is a great team player and has a positive attitude. I highly recommend him for his skills and dedication.'
    },
    {
        user: 'Silviya Maneva',
        title: '.NET Developer, Iristel',
        image: silviya,
        review: "Aryan was a standout in our System Development class, showing off his full-stack chops. He's detail-oriented, tackles tough tasks with ease, and picks up new tech quickly. Plus, his coding passion makes him a great team player. Highly recommend him for his skill and dedication."
    },
    {
        user: 'Jerry Joy',
        title: 'Backend Developer, Collège Lasalle',
        image: jerry,
        review: "Worked with Aryan on hackathon projects, and he's a top-tier React JS and Tailwind CSS pro. Easy to collaborate with and great at communication."
    },
    {
        user: 'Jolson Cruz',
        title: 'Full Stack Developer, Vic Studios',
        image: jolson,
        review: "Too many startup ideas but too little time. This guy loves building something new! Aryan is always coming up with creative solutions to problems we run into while building products."
    },
    
]
export default reviews;