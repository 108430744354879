import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const options = ["home", "projects", "contact"];
  const [active, setActive] = useState(path === "" ? "home" : path);
  return (
    <>
      {/* Desktop Navbar */}
      <div className="hidden md:block dark">
        <div className="w-full border-b border-neutral-100 flex justify-center items-center mb-10 fixed bg-white z-20">
          <nav className="w-full lg:w-5/6 xl:max-w-6xl flex justify-between px-10 py-3">
            <a href="/" className="hover:opacity-75 animate-short">
              <h1 className="font-sans font-semibold text-xl">
                Aryan Arora{" "}
                <span className="text-slate-600 ml-3 text-base font-normal">
                  Software Engineer
                </span>
              </h1>
            </a>
            <div className="flex gap-10 [&>*]:font-semibold items-center">
              {options.map((link) => (
                <button
                  onClick={() => {
                    setActive(link);
                    navigate(`/${link}`);
                  }}
                  key={link}
                  className={`hover:text-slate-700 animate-short capitalize ${
                    active === link ? "text-black" : "text-slate-300"
                  }`}
                >
                  {link}
                </button>
              ))}
              <div className="flex gap-5">
                <a
                  href="https://www.linkedin.com/in/aryanxarora/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="100"
                    height="100"
                    viewBox="0 0 50 50"
                    className="h-6 w-6 fill-neutral-300 hover:fill-black animate-short"
                  >
                    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                  </svg>
                </a>
                <a
                  href="https://github.com/aryanxarora"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    class="h-6 w-6 fill-neutral-300 hover:fill-black animate-short"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 2C6.477 2 2 6.463 2 11.97c0 4.404 2.865 8.14 6.839 9.458.5.092.682-.216.682-.48 0-.236-.008-.864-.013-1.695-2.782.602-3.369-1.337-3.369-1.337-.454-1.151-1.11-1.458-1.11-1.458-.908-.618.069-.606.069-.606 1.003.07 1.531 1.027 1.531 1.027.892 1.524 2.341 1.084 2.91.828.092-.643.35-1.083.636-1.332-2.22-.251-4.555-1.107-4.555-4.927 0-1.088.39-1.979 1.029-2.675-.103-.252-.446-1.266.098-2.638 0 0 .84-.268 2.75 1.022A9.607 9.607 0 0 1 12 6.82c.85.004 1.705.114 2.504.336 1.909-1.29 2.747-1.022 2.747-1.022.546 1.372.202 2.386.1 2.638.64.696 1.028 1.587 1.028 2.675 0 3.83-2.339 4.673-4.566 4.92.359.307.678.915.678 1.846 0 1.332-.012 2.407-.012 2.734 0 .267.18.577.688.48 3.97-1.32 6.833-5.054 6.833-9.458C22 6.463 17.522 2 12 2Z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="md:hidden w-full fixed bottom-10 z-20">
        <div className="w-full flex justify-center px-5">
          <div className="bg-white border border-neutral-100 w-full rounded-full shadow-xl flex justify-around h-14 p-2">
            {options.map((link) => (
              <button
                onClick={() => {
                  setActive(link);
                  navigate(`/${link}`);
                }}
                className={`
                rounded-full w-full px-3 py-1 capitalize font-semibold text-slate-400
                ${active === link ? "bg-black text-white rounded-full" : ""}`}
              >
                {link}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
