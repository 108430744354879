import reviews from "../models/reviews";

const Reviews = () => {
  return (
    <section id="reviews" className="mt-20 w-full">
      <h1 className="font-serif text-3xl md:text-4xl text-center">
        Take their word for it!
      </h1>
      <div className="flex gap-10 overflow-hidden group">
        <div className="mt-10 flex space-x-10 animate-loop-scroll">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="bg-neutral-100 text-black p-7 lg:p-10 mb-5 rounded-2xl w-[400px] md:w-[500px] max-w-none"
            >
              <div className="flex items-center gap-5 mb-5">
                <img
                  src={review.image}
                  alt="aryan-arora"
                  className="w-12 rounded-full"
                />
                <div>
                  <h1 className="font-serif text-xl">{review.user}</h1>
                  <p className="font-mono text-md">{review.title}</p>
                </div>
              </div>
              <p className="font-mono">{review.review}</p>
            </div>
          ))}
        </div>
        <div
          className="mt-10 flex space-x-10 animate-loop-scroll"
          aria-hidden="true"
        >
          {reviews.map((review, index) => (
            <div
              key={index}
              className="bg-neutral-100 text-black p-7 lg:p-10 mb-5 rounded-2xl w-[400px] md:w-[500px] max-w-none"
            >
              <div className="flex items-center gap-5 mb-5">
                <img
                  src={review.image}
                  alt="aryan-arora"
                  className="w-12 rounded-full"
                />
                <div>
                  <h1 className="font-serif text-xl">{review.user}</h1>
                  <p className="font-mono text-md">{review.title}</p>
                </div>
              </div>
              <p className="font-mono">{review.review}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
