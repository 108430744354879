import React from "react";
import Card from "./Card";
import projects from "../models/projects";
import { useEffect } from "react";

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full flex justify-center">
      <div className="w-full lg:w-5/6 xl:max-w-6xl mt-10 md:mt-20 px-5 lg:px-10">
        <h1 className="text-xl font-bold text-neutral-300 mb-10 md:my-20">
          All Projects
        </h1>
        <div className="grid lg:grid-cols-2 gap-20">
          {projects.map((project, index) => (
            <Card key={index} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
