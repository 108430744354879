import react from "../assets/reactjs.png";
import next from "../assets/nextjs.png";
import tailwind from "../assets/tailwind.png";
import typescript from "../assets/typescript.png";
import mongodb from "../assets/mongodb.png";
import nodejs from "../assets/nodejs.png";
import pwa from "../assets/pwa.png";
import spring from "../assets/spring.png";

const TechStack = () => {
  const stack = [
    { tech: "React JS", icon: react },
    { tech: "Next JS", icon: next },
    { tech: "Tailwind CSS", icon: tailwind },
    { tech: "TypeScript", icon: typescript },
    { tech: "MongoDB", icon: mongodb },
    { tech: "Mobile Apps", icon: pwa },
    { tech: "Node JS", icon: nodejs },
    { tech: "Spring Boot", icon: spring },
  ];

  return (
    <section
      id="techstack"
      className="mt-20 md:mt-5 w-full lg:w-4/6 xl:max-w-5xl"
    >
      <h1 className="font-serif text-3xl md:text-4xl text-center">
        Things I'm <strike>good</strike>{" "}
        <span className="text-[#0047FF]">great</span> at...
      </h1>
      <p className="font-mono text-center mb-10">
        with over 3 years of experience
      </p>
      <div className="columns-2 md:columns-4">
        {stack.map((tech, index) => (
          <div className="border border-neutral-200 rounded-lg flex justify-start items-center p-3 lg:px-5 py-5 mb-5 gap-3 h-16">
            <img
              src={tech.icon}
              alt={tech}
              className="w-10 max-h-10 object-contain"
            />
            <h3 className="font-mono text-md lg:text-base">{tech.tech}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TechStack;
