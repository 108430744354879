import Hero from "./Hero";
import TechStack from "./TechStack";
import Work from "./Work";
import Reviews from "./Reviews";
import { useEffect } from "react";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col items-center px-5 ">
      {/* <Navbar /> */}
      <div className="w-full md:h-24"></div>
      <Hero />
      <TechStack />
      <Work />
      <Reviews />
    </div>
  );
};

export default Portfolio;
